const deviceType = () => {
  const isIpad =
    /Macintosh/i.test(navigator.userAgent) &&
    navigator.maxTouchPoints &&
    navigator.maxTouchPoints > 1

  const ua = navigator.userAgent
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua) || isIpad) {
    return 'tablet'
  } else if (
    /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
      ua
    )
  ) {
    return 'mobile'
  }
  return 'desktop'
}

const isMobile = () => {
  return deviceType() === 'mobile'
}

const isTablet = () => {
  return deviceType() === 'tablet'
}

const isDesktop = () => {
  return deviceType() === 'desktop'
}

export { deviceType, isTablet, isMobile, isDesktop }
